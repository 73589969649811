<template>
  <div class="mb-10">
    <v-container grid-list-xs>
      <v-icon class="my-10" color="black" @click="navigateToProductDemo"
        >mdi-chevron-left</v-icon
      >
      <v-row>
        <v-col cols="8" class="py-0">
          <div class="flex flex-column source">
            <h5 class="blue-grey--text text--lighten-3">
              {{ selectedProduct.tags[0].name }}
            </h5>
            <h2>{{ selectedProduct.name }}</h2>
          </div>
        </v-col>
        <v-col cols="4" class="py-0">
          <div class="flex flex-column source text-right">
            <h5
              v-if="selectedProduct.before_discount"
              class="blue-grey--text text--lighten-3 line-through"
            >
              RM {{ parseFloat(selectedProduct.before_discount).toFixed(2) }}
            </h5>
            <h2 v-if="this.selectedVariants != 0">
              {{
                selectedProduct.price_type == 0
                  ? "RM" + parseFloat(this.selectedVariants[0].price).toFixed(2)
                  : ""
              }}
            </h2>
            <h2 v-else>
              {{ "RM" + parseFloat(selectedProduct.unit_price).toFixed(2) }}
            </h2>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="flex pt-0 pb-0">
          <div>
            <v-rating
              background-color="grey"
              color="teal"
              length="5"
              dense
              readonly
              half-increments
              size="15"
              :value="averageRating"
            ></v-rating>
          </div>
          <div>
            <span
              class="teal--text text-caption underline"
              v-if="averageRating"
            >
              ({{ averageRating.toFixed(2) }})
            </span>
            <span class="teal--text text-caption underline" v-else>
              (0.00)
            </span>
          </div>
          <v-divider vertical class="ml-1 mr-1"></v-divider>
          <div>
            <span class="teal--text text-caption underline">
              {{ productReview.length }} Ratings
            </span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card color="white" flat>
            <v-row>
              <v-col cols="12" class="pb-0">
                <div v-if="selectedProduct.media_urls[0]">
                  <v-img
                    max-height="50vh"
                    width="100%"
                    class="rounded-lg my-5"
                    contain
                    v-if="selectedProduct.media_urls"
                    :src="image(selectedProduct.media_urls[0])"
                  ></v-img>
                </div>
                <div v-else>
                  <v-img
                    max-height="50vh"
                    width="100%"
                    class="rounded-lg my-5"
                    contain
                    v-if="selectedProduct.media_urls"
                    :src="emptyImage()"
                  ></v-img>
                </div>
                <!-- <div v-else>
                  <v-sheet height="30vh" width="100%" color="white">
                    <span class="height text-center"
                      ><h3 class="py-16 blue-grey--text text-lighten-3 height">
                        placeholder
                      </h3></span
                    >
                  </v-sheet>
                </div> -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="py-0"> </v-col>
            </v-row>
          </v-card>
          <v-card rounded="xl" elevation="5">
            <div class="mx-3 py-5">
              <div></div>
              <div v-if="selectedProduct.description">
                <h5 class="nunito font-weight-bold mb-1">Description</h5>
                <h4 class="nunito font-weight-bold mb-3">
                  {{ selectedProduct.description }}
                </h4>
              </div>
              <div v-if="selectedProduct.total_left">
                <h5 class="nunito font-weight-bold mb-1">Stocks</h5>
                <h4 class="nunito font-weight-bold mb-3">
                  {{ selectedProduct.total_left }} left
                </h4>
              </div>
              <div v-if="selectedProduct.modifier_sets.length > 0">
                <div
                  v-for="(value, i) in selectedProduct.modifier_sets"
                  :key="i"
                >
                  <h5 class="nunito font-weight-bold mb-1">{{ value.name }}</h5>
                  <div class="flex overflow-auto">
                    <div v-for="(n, index) in value.modifiers" :key="index">
                      <v-card
                        class="pa-3 my-3 mr-3"
                        :color="
                          selectedQuantity.length > 0
                            ? selectedQuantity.some((d) => d.id == n.id)
                              ? 'leaf white--text'
                              : 'white'
                            : 'white'
                        "
                        @click="selectModifier(n, i)"
                      >
                        <div class="flex flex-column">
                          <h5 class="nunito">{{ showMod(n) }}</h5>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  selectedProduct.variants.length > 0 &&
                  selectedProduct.price_type == 0
                "
              >
                <div>
                  <h5 class="nunito font-weight-bold mb-1">Variants</h5>
                  <div
                    v-if="selectedProduct.variant"
                    class="flex overflow-auto"
                  >
                    <div
                      v-for="(value, i) in selectedProduct.variants"
                      :key="i"
                    >
                      <v-card
                        class="pa-3 my-3 mr-3"
                        :color="
                          selectedVariants.length > 0
                            ? selectedVariants[0].id == value.id
                              ? 'leaf white--text'
                              : 'white'
                            : 'white'
                        "
                        @click="selectVariants(value, i)"
                      >
                        <div class="flex flex-column">
                          <h5 class="nunito">{{ value.name }}</h5>
                          <h5 class="nunito">{{ showAmt(value) }}</h5>
                        </div>
                      </v-card>
                    </div>
                  </div>
                  <div v-else class="flex overflow-auto">
                    <div
                      v-for="(value, i) in selectedProduct.variants"
                      :key="i"
                    >
                      <v-card
                        class="pa-3 my-3 mr-3"
                        :color="
                          selectedVariants.length > 0
                            ? selectedVariants[0].id == value.id
                              ? 'leaf white--text'
                              : 'white'
                            : 'white'
                        "
                        @click="selectVariants(value, i)"
                      >
                        <div class="flex flex-column">
                          <h5 class="nunito">{{ value.name }}</h5>
                          <h5 class="nunito">{{ showAmt(value) }}</h5>
                        </div>
                      </v-card>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-column">
                <h5 class="nunito font-weight-bold mb-1">Quantity</h5>
                <v-btn-toggle color="#D9E02F" dense rounded class="mb-2">
                  <v-btn
                    @click="quantity === 0 ? 0 : quantity--"
                    color="#D9E02F"
                  >
                    <v-icon small>mdi-minus</v-icon>
                  </v-btn>
                  <v-sheet class="px-5 pt-1" color="#D9E02F">
                    <span class="mt-1">{{ quantity }}</span>
                  </v-sheet>

                  <v-btn
                    color="#D9E02F"
                    @click="
                      selectedProduct.total_left &&
                      quantity > selectedProduct.total_left - 1
                        ? quantity
                        : quantity++
                    "
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </div>
              <div class="my-3">
                <h5 class="nunito mb-2 font-weight-bold">Remarks</h5>
                <v-btn
                  color="#D9E02F"
                  class="px-8"
                  rounded
                  @click="isRemark = true"
                  >add remarks</v-btn
                >
              </div>
              <div>
                <v-textarea
                  v-if="isRemark"
                  outlined
                  color="gold"
                  name="input-7-4"
                  placeholder="E.g : No Onions please"
                  flat
                  v-model="remark"
                  dense
                  :rows="2"
                ></v-textarea>
              </div>
            </div>
          </v-card>
          <div>
            <v-btn
              block
              color="#279474"
              class="my-3"
              dark
              rounded
              elevation="0"
            >
              <v-icon class="mr-2">mdi-cart-plus</v-icon>
              ADD TO CART</v-btn
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Product",
  data() {
    return {
      quantity: 1,
      productReview: [],
      selectedProduct: null,
      selectedQuantity: [],
      selectedVariants: [],
      isRemark: false,
      remark: "",
      averageRating: null,
    };
  },

  computed: {
    ...mapGetters({
      product: "getSelectedProduct",
      // franchise: "getFranchise",
      productReview: "getProductReview",
    }),
  },
  watch: {
    productReview: function () {
      this.calculateRating();
    },
  },

  methods: {
    popstateEventAction() {
      // ... some action triggered when the back button is clicked
      console.log("anyyeong back");
      this.removePopstateEventAction();
    },
    removePopstateEventAction() {
      window.removeEventListener("popstate", this.popstateEventAction);
    },
    loadData() {
      let data = localStorage.getItem("selectedProduct");
      if (data) {
        let dataObj = JSON.parse(data);
        this.selectedProduct = dataObj;
        this.$router.push({ name: "ProductDemo", params: { id: dataObj.id } });
      }
    },
    beforeRouteUpdate(to, from, next) {
      this.loadData();
      next();
    },
    mounted() {
      this.loadData();
    },
    destroyed() {
      localStorage.removeItem("selectedProduct");
    },
    created() {
      window.addEventListener("popstate", this.popstateEventAction);
      window.addEventListener("beforeunload", this.saveData);
    },
    beforeDestroy() {
      window.removeEventListener("beforeunload", this.saveData);
    },

    navigateToProductDemo() {
      this.$router.push({
        name: "Demo",
        params: { child: this.$route.params.child, page: "demo" },
      });
      localStorage.removeItem("selectedProduct");
    },

    getout(v, i) {
      console.log(v, i);
    },

    image(i) {
      if (i.s512) {
        return i.s512;
      } else
        return (
          "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
          i.original
        );
    },
    emptyImage() {
      return (
        "https://s3-ap-southeast-1.amazonaws.com/assets.getorders/" +
        this.franchise.logo
      );
    },

    back() {
      this.$router.push({
        name: "Page",
        params: { child: this.$route.params.child, page: "productDemo" },
      });
    },

    showMod(mod) {
      var prefix = mod.mode == 1 ? "+ " : mod.mode == 2 ? "- " : "",
        suffix = mod.mode == 3 ? "% " : "";
      if (typeof mod.amount == "string") {
        mod.amount = parseFloat(mod.amount);
      }
      var amt =
        mod.amount > 0 ? ` ${prefix} RM${mod.amount.toFixed(2)}${suffix}` : "";
      return `${mod.name}${amt}`;
    },

    showAmt(vars) {
      if (typeof vars.price == "string") {
        vars.price = parseFloat(vars.price);
      }

      var amt = vars.price > 0 ? ` RM${vars.price.toFixed(2)}` : "";
      return `${amt}`;
    },

    selectModifier(v) {
      if (this.selectedQuantity.some((d) => d.id == v.id)) {
        var p = this.selectedQuantity.filter(function (x) {
          return x.id != v.id;
        });
        this.selectedQuantity = p;
      } else {
        this.selectedQuantity.push(v);
      }
    },

    selectVariants(values) {
      if (values) {
        var val = {
          id: values.id,
          name: values.name,
          price: values.price,
          code: values.code,
        };
        this.selectedVariants = [val];
      }
    },

    limitModifiers(uuid, qty) {
      let cart_list = [];
      let adding = () => {
        this.product.order_id = uuid;
        this.product.quantity = qty;
        cart_list = [this.selectedQuantity, ...cart_list];
        window.fbq("track", "AddToCart");
        // close();
      };

      if (this.product.modifier_sets.length > 0) {
        let k = this.product.modifier_sets.map((d) => {
          // console.log(i, "i");
          let z = this.selectedQuantity.filter((l) =>
            d.modifiers.map((j) => j.id).includes(l.id)
          );
          let ret = {
            status: true,
            message: "",
          };

          if (z.length > d.max_apply_limit && d.max_apply_limit != 0) {
            ret.status = false;
            ret.message =
              "Please choose not more than " +
              d.max_apply_limit +
              " for " +
              d.name;
            return ret;
          } else if (z.length < d.min_apply_limit != 0) {
            ret.status = false;
            ret.message =
              "Please choose minimum of " +
              d.min_apply_limit +
              " for " +
              d.name;
            return ret;
          } else return ret;
        });

        let error = k.filter((d) => !d.status);

        if (error.length > 0) {
          alert(error[0].message);
          return false;
        } else {
          adding();
        }
      } else {
        adding();
      }
    },

    calculateRating() {
      var total = 0;
      this.productReview.map((r) => {
        total += r.rating;
      });
      this.averageRating = total / this.productReview.length;
    },
  },
  mounted() {
    this.loadData();
  },
  beforeMount() {
    var k = { ...this.product };
    this.selectedProduct = k;

    if (k.quantity) {
      this.quantity = k.quantity;
    }

    if (k.remarks) {
      this.isRemark = true;
      this.remark = k.remarks;
    }

    if (k.modifiers) {
      this.selectedQuantity = k.modifiers;
    }

    if (k.variant) {
      this.selectedVariants = k.variant;
    } else {
      var p = k.variants;
      for (let [key, value] of Object.entries(p)) {
        if (value.default) {
          this.selectedVariants = k.variants[key];
          this.selectVariants(this.selectedVariants);
        }
      }
    }

    window.scrollTo(0, 0);
    this.$store.dispatch("fetchReview", this.selectedProduct.id);
  },
};
</script>
<style>
.height {
  height: 100%;
}
.line-through {
  text-decoration: line-through red;
}
</style>
